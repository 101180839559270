<template>
	<div class="container-gender-cards">
 		<div class="title">
			<h4>{{ title }}</h4>
			<div class="badge badge-danger" v-if="specimens.length > 0">{{ specimens.length }}</div>
		</div>
		<div class="bg-white py-2 found" v-if="specimens.length == 0 && chicks.length == 0">
			<p class="mb-0 text-center">Sin ejemplares</p>
		</div>

		<div v-if="correlative && chicks.length > 0" class="specimens-correlative">
			<div
				v-for="chick in chicks"
				style="
					background-color: #7367ef;
					padding: 0.2rem;
					border-radius: 0.5rem;
					color: white;
					font-weight: 700;
				"
			>
				{{ chick.correlative }}
			</div>
		</div>

		<div  v-if="specimens.length > 0" :class="`${padding ? 'specimens  ppadding' : 'specimens  sppadding '}`">
			<div v-for="(item, index) in specimens" :key="index">
				<cardSpecimen :specimen="item.specimen" style="margin-bottom: 2.2rem" />
			</div>
		</div>
	</div>
</template>

<script>
import cardSpecimen from "./CardSpecimen.vue"
export default {
	name: "ContainerGenderCard",
	components: {
		cardSpecimen,
	},
	props: {
		specimens: {
			type: Array,
			default: () => [],
		},
		chicks: {
			type: Array,
			default: () => [],
		},
		title: {
			type: String,
			default: "Sin texto",
		},
		padding: {
			type: Boolean,
		},

		correlative: {
			type: Boolean,
		},
	},
}
</script>

<style lang="scss" scoped>
.container-gender-cards {
	border-radius: 12px;
	// overflow: hidden;
	.title {
		border-top-right-radius: 12px;
		border-top-left-radius: 12px;
		background: #f5f5f5;
		// background: red;
		padding: 1rem 2rem;
		margin-bottom: 0;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		h4 {
			font-weight: bold;
			font-size: 20px;
			margin-bottom: 0;
		}
		.badge {
			margin-left: 1rem;
		}
	}
	// padding: 2rem 4rem;
	.specimens {
		border: 2px solid #f2f2f2;
		background: white;
		display: grid;
		grid-template-columns: 1fr;

		@media screen and (min-width: 700px) {
			grid-template-columns: 1fr 1fr;
		}
		@media screen and (min-width: 1000px) {
			grid-template-columns: 1fr 1fr 1fr;
		}
		@media screen and (min-width: 1400px) {
			grid-template-columns: 1fr 1fr;
		}
	}
	.found {
		border: 2px solid #f2f2f2;
		background: white;
	}
}

.sppadding {
	gap: 2rem;
	padding: 2rem;
}
.ppadding {
	gap: 1rem;
	padding: .5rem;
}

.specimens-correlative {
	border: 2px solid #f2f2f2;
	background: white;
	display: flex;
	padding: 1rem;
	flex-wrap: wrap;
	gap: 1rem;
}
</style>
