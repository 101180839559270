<template>
	<div>
		<b-overlay :show="isLoading">
			<b-row>
				<b-col cols="12" xl="6" class="mb-1">
					<ValidationProvider rules="required" v-slot="{ errors }" class="w-100">
						<div class="d-flex align-items-center">
							<b-form-group
								label="Fecha de retiro (Corral)"
								:invalid-feedback="errors[0]"
								:state="errors[0] ? false : null"
								class="mb-0 w-100"
								:description="is_corral_module ? '' : 'Afectará a los pollos de un corral.'"
							>
								<flat-pickr
									v-model="date"
									class="form-control bg-white"
									placeholder="Seleccionar fechas"
									:class="errors[0] ? 'is-invalid' : ''"
								/>
							</b-form-group>
						</div>
					</ValidationProvider>
				</b-col>

				<b-col cols="12" xl="6" class="mb-1">
					<div class="d-flex align-items-center w-100">
						<b-form-group label="Cintillo" class="w-100 mb-0">
							<b-input-group class="">
								<b-form-input v-model="cInput" placeholder="Cintillo..." @keyup.enter="cSearch" />
								<b-input-group-append>
									<b-button size="sm" variant="outline-primary" @click="cSearch">Agregar</b-button>
								</b-input-group-append>
							</b-input-group>
						</b-form-group>
					</div>
				</b-col>
			</b-row>
			<div class="table-retirement">
				<div class="header" :class="[is_corral_module ? 'corral-body-table' : '']">
					<p>Cintillo</p>
					<p>Placa</p>
					<p>Acciones</p>
				</div>
				<validation-observer v-if="evolutionChicks.length > 0" ref="form">
					<RetirementChicksByCorralItem
						@deleteChickByArray="deleteChickByArray"
						:chick="chick"
						v-for="(chick, index) in evolutionChicks"
						:key="index"
						:is_corral_module="is_corral_module"
					/>
				</validation-observer>
				<div v-else>
					<p class="text-center font-weight-bold mt-1">Sin cintillos por mostrar</p>
				</div>
			</div>
			<div class="d-flex justify-content-end mt-2">
				<button class="btn btn-primary" @click="processingRetirementCorral">Guardar</button>
			</div>
			<b-modal title="SELECCIONAR UN CINTILLO" v-model="showModalRepeated" centered hide-footer>
				<b-row class="mb-1"
					><b-col class="text-center mb-1" cols="6" v-for="chick in chicksRepeated" :key="chick.id">
						<div class="chick-item" @click="addCintilloToCorral(chick), (showModalRepeated = false)">
							<p class="mb-0">{{ chick.label }}</p>
						</div>
						<br />
						<small v-b-tooltip.hover.top="`Corral`" v-if="chick.in_corral != 0">{{
							chick.alias_corral
						}}</small>
					</b-col></b-row
				>
			</b-modal>
		</b-overlay>
	</div>
</template>

<script>
import corralService from "@/services/encaste/corral.service";
import RetirementChicksByCorralItem from "./RetirementChicksByCorralItem.vue";
import EncasteChicksService from "@/services/encaste/chicks.service";
import moment from "moment";

export default {
	name: "RetirementChicksByCorral",
	components: {
		RetirementChicksByCorralItem,
	},
	props: {
		chicks: {
			type: Array,
			default: () => {},
		},
		posturas: {
			type: Array,
			default: () => {},
		},
		corral_id: {
			type: Number,
			default: null,
		},
		is_corral_module: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			selectAllDeworm: false,
			chicksArray: [],
			date: moment(new Date()).format("YYYY-MM-DD"),
			chickSelect: null,
			evolutionChicks: [],
			repeat: [],
			isLoading: false,
			cInput: "",
			showModalRepeated: false,
			chicksRepeated: [],
		};
	},
	computed: {
		lenChicks() {
			return this.chicks.length;
		},
	},
	mounted() {
		this.evolutionChicks = this.chicks;
	},
	methods: {
		changeSelectAllDeworn() {
			// console.log("ASD", this.selectAllDeworm);
			for (let index = 0; index < this.chicks.length; index++) {
				// const element = chicks[index];
				this.chicks[index].check_deworm = this.selectAllDeworm;
			}
		},
		addCintilloToCorral(cintillo) {
			const searchChick = this.evolutionChicks.findIndex((it) => it.correlative == cintillo.label);
			const chick = {
				check: false,
				check_deworm: false,
				correlative: cintillo.label,
				gender: 1,
				id: cintillo.id,
				image: null,
				key: 1,
				plate: null,
				specimen_id: null,
				status: 2,
				type: cintillo.gender_id == 1 ? "machos" : "hembras",
				visible: true,
				code_corral: cintillo.code_corral,
			};
			// console.log('AS', chick);

			if (searchChick == -1) {
				let index = null;
				if (this.is_corral_module) {
					index = this.posturas.findIndex((it) => {
						let inRe = false;
						const chicks = it.chicks;
						chicks.forEach((element) => {
							if (element.id == cintillo.id) {
								inRe = true;
							}
						});
						return inRe;
					});
				} else {
				}

				this.chicksArray = [];
				this.chickSelect = null;
				this.$emit("addRetirementChick", {
					chicken: chick,
					key: index,
					type: cintillo.gender_id == 1 ? "machos" : "hembras",
					retirement_check: true,
				});
				// if (this.is_corral_module) {

				// } else {
				//     this.evolutionChicks.push(chick);
				// }
			}
		},
		deleteChickByArray(chick) {
			this.evolutionChicks = this.evolutionChicks.filter((it) => it.id != chick.id);
			this.refreshCorrales(chick);
		},
		async processingRetirementCorral() {
			if (!(await this.$refs.form.validate())) {
				this.showToast(
					"danger",
					"top-right",
					"Campos Incompletos",
					"AlertCircleIcon",
					"Faltan campos por llenar"
				);
				return;
			}

			const { isConfirmed } = await this.showConfirmSwal();
			if (!isConfirmed) return;

			this.isPreloading();
			// console.log("SAVE", this.chicks[0].image, await this.toBase64(this.chicks[0].image));
			// let db =  this.evolutionChicks.map(async (chick) => {
			// 	const image = await this.toBase64(chick.url);
			// 	return { ...chick, image };
			// });
			// const dbAll = await Promise.all(db);

			for (let index = 0; index < this.evolutionChicks.length; index++) {
				// const element = this.evolutionChicks[index];

				this.evolutionChicks[index].url &&
					(this.evolutionChicks[index].image = await this.toBase64(this.evolutionChicks[index].url));
			}

			let out = { chicks: [] };
			out.chicks = this.evolutionChicks.map((chick) => ({ id: chick.id, plate: chick.plate }));
			const { message, repeat } = await corralService.insertDewornChiks(out);

			if (repeat.length > 0) {
				this.showToast("warning", "top-right", "Placas existentes", "WarningIcon", repeat.join(", "));
			}

			this.repeat = repeat;

			this.evolutionChicks = this.evolutionChicks.filter((it) => repeat.includes(it.plate));

			this.isPreloading(false);

			this.$emit("refresh", repeat);
		},
		refreshCorrales(chick) {
			this.$emit("deleteAddRetirementChick", chick);

			if (this.is_corral_module) {
				const index = this.posturas.findIndex((it) => {
					let inRe = false;
					const chicks = it.chicks;
					chicks.forEach((element) => {
						if (element.id == chick.id) {
							inRe = true;
						}
					});
					return inRe;
				});

				this.posturas[index][chick.type] = this.posturas[index][chick.type].map((item) => {
					if (item.id == chick.id) {
						return {
							...item,
							check: false,
						};
					} else {
						return {
							...item,
						};
					}
				});
			}
		},
		toBase64(file) {
			return new Promise((resolve, reject) => {
				const reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = () => resolve(reader.result);
				reader.onerror = (error) => reject(error);
			});
		},
		async cSearch() {
			this.isLoading = true;
			const { message, chicks } = await corralService.getChicks({
				search: this.cInput,
			});
			// verificar si existe pero esta en otro corral
			let match = chicks.find((chick) => chick.correlative == this.cInput);
			if (match != undefined && match.corral_id != null) {
				this.showToast(
					"danger",
					"top-right",
					"Error",
					"AlertIcon",
					`El correlativo ya existe en el corral ${match.corral_alias}`
				);
				this.isLoading = false;
				return;
			}
			//
			// let available = chicks.filter((chick) => chick.corral_id == null);
			let available = chicks.filter((chick) => chick.emplaced == 0);
			available = available.map((it) => ({
				label: it.correlative,
				value: it.id,
				id: it.id,
				in_corral: it.in_corral,
				code_corral: it.code_corral,
				alias_corral: it.corral_alias,
				gender: it.gender,
			}));
			// 21-50
			if (available.length == 1) {
				this.addCintilloToCorral(available[0]);
			} else if (available.length == 0) {
				this.showToast("danger", "top-right", "Error", "AlertIcon", "Codigo no existente");
			} else {
				//
				this.chicksRepeated = available;
				this.showModalRepeated = true;
			}
			this.cInput = "";
			this.isLoading = false;
		},
	},
	watch: {
		lenChicks() {
			this.evolutionChicks = this.chicks;
		},
	},
};
</script>

<style lang="scss" scoped>
.table-retirement {
	max-height: 700px;
	overflow: auto;
	.header {
		background: #f4f1f7;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr 0.6fr 0.6fr;
		p {
			margin-bottom: 0;
			padding: 1rem;
			text-align: center;
			text-transform: uppercase;
			font-weight: bolder;
		}
	}
}
.corral-body-table {
	// grid-template-columns: 0.8fr .8fr 0.8fr 1fr 1fr !important;
	grid-template-columns: 1fr 1fr 1fr !important;
}
.chick-item {
	display: inline-block;
	background: #7367f0;
	width: auto;
	position: relative;
	padding: 0.3rem 0.6rem;
	margin-bottom: 0.2rem;
	border-radius: 4px;
	cursor: pointer;
	p {
		color: #fff;
		font-weight: bolder;
	}
}
</style>
