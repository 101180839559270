import axios from "@/axios"

const url = "/api/corrales/"

class CorralChicksService {
	async store({ start_date, cintillo_alas, alias, comment }) {
		try {
			const { data } = await axios.post(`${url}`, {
				start_date,
				cintillo_alas,
				alias,
				comment,
			})
			return data
		} catch (err) {
			console.error(err)
		}
	}
	async index({ page, perpage, search, orderby, statusId }) {
		try {
			const { data } = await axios.get(`${url}`, {
				params: {
					page,
					perpage,
					search,
					orderby,
					statusId,
				},
			})
			return data
		} catch (err) {
			console.error(err)
		}
	}

	async nullify({ corral_id }) {
		try {
			const { data } = await axios.delete(`${url}${corral_id}`)
			return data
		} catch (err) {
			console.error(err)
		}
	}

	async update({ corral_id, start_date, status, c_code, chicks, cintillo_alas, add_new }) {
		try {
			const { data } = await axios.put(`${url}update/${corral_id}`, {
				start_date,
				status,
				c_code,
				chicks,
				cintillo_alas,
				add_new,
			})
			return data
		} catch (err) {
			console.error(err)
		}
	}

	async getPosturas({ corral_id, search }) {
		try {
			const { data } = await axios.get(`${url}${corral_id}/get-posturas`, {
				params: {
					search,
				},
			})
			return data
		} catch (err) {
			console.error(err)
		}
	}

	async getChicks({ search, corral_id = null }) {
		try {
			const { data } = await axios.get(`${url}get-chicks`, {
				params: { search, corral_id },
			})
			return data
		} catch (err) {
			console.error(err)
		}
	}

	async getCounters({ start_date, end_date }) {
		try {
			const { data } = await axios.get(`${url}get-counter-corrales-by-status`, {
				params: {
					start_date,
					end_date,
				},
			})
			return data
		} catch (err) {
			console.error(err)
		}
	}

	async updateColumnsCorral({ start_date, comment, alias, id }) {
		const data = await axios.put(`${url}update-columns-corral`, {
			start_date,
			comment,
			alias,
			id,
		})
		return data
	}

	async viewChildrensByParents({ padrillo_id, madrilla_id, corral_id }) {
		const data = await axios.get(`${url}get-children-by-parents`, {
			params: { padrillo_id, madrilla_id, corral_id },
		})
		return data
	}

	// -------0--------
	async getPlacationRequests() {
		try {
			const { data } = await axios.get(`${url}get-deworm-chick`)
			return data
		} catch (err) {
			console.error(err)
		}
	}

	async insertDewornChiks({ chicks }) {
		try {
			const { data } = await axios.post(`${url}insert-masive-deworn-chicks`, { chicks })
			return data.data
		} catch (err) {
			console.error(err)
		}
	}

	async getCountPlacationRequests() {
		try {
			const { data } = await axios.get(`${url}count-deworm-chick`)
			return data
		} catch (err) {
			console.error(err)
		}
	}

	async getCounterChildrenAndGender({ madrilla_id, padrillo_id, corral_id }) {
		try {
			const { data } = await axios.get(`${url}get-counter-children-and-gender`, {
				params: {
					madrilla_id,
					padrillo_id,
					corral_id,
				},
			})
			return data
		} catch (error) {
			return error.response.data
		}
	}
	async changeCorralChicks({ chicks }) {
		const data = await axios.put(`${url}change-corral-chicks`, {
			chicks,
		})
		return data
	}

	async getCorralesTracking({ specimen_id, chick_id }) {
		const { data } = await axios.get(`${url}get-corrales-by-specimen`, {
			params: { specimen_id, chick_id },
		})
		return data
	}

	async insertMassiveDeworn({ chicks, corral_id }) {
		const { data } = await axios.post(`${url}insert-masive-deworn-by-corral`, {
			chicks,
			corral_id,
		})
		return data
	}

	async insertMassiveVitamin({ chicks, corral_id, type_id, vitamin_id, days }) {
		const { data } = await axios.post(`${url}insert-masive-vitamins-by-corral`, {
			chicks,
			corral_id,
			type_id,
			vitamin_id,
			days,
		})
		return data
	}

	async getChickDewornTracking({ chick_id }) {
		const { data } = await axios.get(`${url}get-deworns-chick/${chick_id}`)
		return data
	}

	async getChickVitaminTracking({ chick_id }) {
		const { data } = await axios.get(`${url}get-vitamins-chick/${chick_id}`)
		return data
	}

	async parentsInvolvedInThePlaypen({ category_id, corral_id }) {
		const { data } = await axios.get(`${url}parents-involved-in-the-playpen`, {
			params: {
				category_id,
				corral_id,
			},
		})
		return data
	}

	async getHcDewornCorrales({ corral_id }) {
		const { data } = await axios.get(`${url}get-hc-deworn-corrales/${corral_id}`, {})
		return data
	}

	async getHcVitaminsCorrales({ corral_id }) {
		const { data } = await axios.get(`${url}get-hc-vitamins-corrales/${corral_id}`, {})
		return data
	}

	async getCounterHcChick({ chick_id }) {
		const { data } = await axios.get(`${url}get-counter-hc-chick/${chick_id}`, {})
		return data
	}

	async removeAllChicks({ corral_id }) {
		const { data } = await axios.post(`${url}remove-chick-total-by-corral/${corral_id}`)
		return data
	}

	async getSpecimenBrothersByChick({ id }) {
		const { data } = await axios.get(`${url}get-brothers-specimens-by-chick/${id}`)
		return data
	}
}
export default new CorralChicksService()
