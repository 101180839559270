<template>
	<div class="body-table" :class="[is_corral_module ? 'corral-body-table' : '']">
		<!-- <h3>Chick</h3>
		{{ chick }} -->
		<div class="p-1 d-flex align-items-center justify-content-center">
			<span>{{ chick.correlative }}</span> 
		</div>
		<div class="p-1 d-flex align-items-center justify-content-center">
			<ValidationProvider rules="required" v-slot="{ errors }" class="w-100">
				<b-form-input
					:state="errors[0] ? false : null"
					v-model="chick.plate"
					placeholder="Placa"
				></b-form-input>
				<small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
			</ValidationProvider>
		</div>
		<!-- 
		<div class="p-1 d-flex align-items-center justify-content-center">
			<ValidationProvider
				rules="v-true"
				v-slot="{ errors }"
				class="w-100 text-center d-flex justify-content-center align-items-center flex-column"
			>
				<div class="check-valid">
					<b-form-checkbox
						v-b-tooltip.hover
						title="Desparasitar"
						class="text-center d-flex justify-content-center align-items-center"
						v-model="chick.check_deworm"
					>
					</b-form-checkbox>
				</div>
				<small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
			</ValidationProvider>
		</div>

		<div class="p-1 d-flex align-items-center justify-content-center">
			<ValidationProvider rules="required" v-slot="{ errors }" class="w-100">
				<SelectableImage v-model="chick.url" />
				<small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
			</ValidationProvider>
		</div> -->

		<div v-if="!is_corral_module" class="d-flex align-items-center justify-content-center">
			{{ chick.code_corral ? chick.code_corral : "---" }}
		</div>

		<div class="d-flex align-items-center justify-content-center">
			<FeatherIcon
				v-b-tooltip.hover
				title="Eliminar de la tabla"
				@click="$emit('deleteChickByArray', chick)"
				icon="TrashIcon"
				size="24"
				class="text-danger cursor-pointer"
			/>
		</div>
	</div>
</template>

<script>
import SelectableImage from "@/views/amg/specimens/components/SelectableImage.vue";

export default {
	name: "RetirementChicksByCorralItem",
	components: {
		SelectableImage,
	},
	props: {
		chick: {
			type: Object,
			default: () => {},
		},
		is_corral_module: {
			type: Boolean,
			default: false,
		},
	},
};
</script>

<style lang="scss" scoped>
.body-table {
	// background: #F4F1F7;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 0.6fr 0.6fr;
	position: relative;
	p {
		margin-bottom: 0;
		padding: 1rem;
		text-align: center;
	}
	.icon {
		position: absolute;
		top: 1rem;
		right: 1rem;
	}
}
.corral-body-table {
	grid-template-columns: 0.8fr 0.8fr 0.8fr 1fr 1fr;
}
.check-valid {
	height: 20px;
	width: 20px;
}
</style>
