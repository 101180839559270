<template>
	<b-modal centered hide-footer size="lg" :title="title" v-model="isActive" @hidden="handleHidden">
		<b-overlay :show="isLoading">
			<!-- <pre>{{ info }}</pre> -->
			<b-table-simple>
				<b-thead class="text-center">
					<b-tr>
						<b-th>Corral</b-th>
						<!-- <b-th>Estado</b-th> -->
						<b-th>Ingresado por</b-th>
						<!-- <b-th>Salida</b-th> -->
					</b-tr>
				</b-thead>
				<b-tbody class="text-center">
					<b-tr v-for="row in rows" :key="row.id">
						<b-td>{{ row.corral_alias || "---" }} / {{ row.corral_code || "---" }}</b-td>
						<!-- <b-td>{{ parseStatus(row.status_id) }}</b-td> -->

						<b-td>
							<span>{{ row.created_by.name }}</span>
							<br />
							<small>{{ row.created_by.date | myGlobalDayShort }}</small>
						</b-td>
						<!-- <b-td>
							<template v-if="row.end_date">
								<span>{{ row.updated_by }}</span>
								<br />
								<small>{{ row.end_date | myGlobalDayShort }}</small>
							</template>
							<span v-else>---</span>
						</b-td> -->
					</b-tr>
				</b-tbody>
			</b-table-simple>
		</b-overlay>
	</b-modal>
</template>

<script>
import corralService from "@/services/encaste/corral.service"

export default {
	props: {
		show: Boolean,
		info: Object,
	},
	data: () => ({
		isActive: false,
		isLoading: false,
		title: null,
		rows: [],
	}),
	methods: {
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.title = null
			this.chicks = []
			this.$emit("closing")
		},
		async getRows() {
			this.isLoading = true
			let body = {}
			if (this.info.dashboard) {
				body.specimen_id = this.info.id
			} else {
				body.chick_id = this.info.id
			}
			const { data } = await corralService.getCorralesTracking(body)
			this.rows = data
			this.isLoading = false
		},
		parseStatus(status) {
			switch (status) {
				case 1:
					return "PENDIENTE"
				case 2:
					return "CANCELADO"
				case 3:
					return "RETIRADO"
			}
		},
	},
	watch: {
		show(val) {
			if (val) {
				this.isActive = val
				this.getRows()
				if (this.info.dashboard) {
					this.title = `Tracking de corrales de ${this.info.alias || this.info.plate}`
				} else {
					this.title = `Tracking de corrales de ${this.info.correlative}`
				}
			}
		},
	},
}
</script>
